<template>
  <div class="container">
    <div>
      <header class="modal-card-head">
        <p class="modal-card-title">Merci {{ user.name }} !</p>
      </header>
      <section class="modal-card-body">
        Le paiement a été accepté.<br>
        <b-field>
          Vous êtes inscrit à l'événement
        </b-field>
        <b-field>
          <strong>{{ myEvent.name }}</strong>
        </b-field>
        <b-field>
          <b-button type="is-success" @click="$router.push({ name: 'Home' })">Retourner à l'accueil</b-button>
        </b-field>
      </section>
    </div>
    <br>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'RegisterForm',
  data() {
    return {
      user: {}
    }
  },
  computed: {
    ...mapGetters(['myEvent'])
  },
  beforeMount() {
    this.$store.dispatch('getUser')
      .then((data) => {
        this.user = data;
        this.form = this.user;
      })
      .catch(() => {
        this.showError('Impossible de charger le compte.')
        this.$store.dispatch('logout')
      });
    this.nb = this.nbMyProducts
    this.total = this.myTotalPrice
    this.order = this.$store.getters.myOrder
    this.$store.commit('removeOrder')
    this.$store.commit('EMPTY_CART')
  },
  mounted() {
    this.$store.commit('doneEvent', false)
  }
}
</script>
